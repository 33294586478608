import axios from 'axios';
import { computed } from '@vue/reactivity';

const TIMEOUT = 1000000;

const onRequestSuccess = (config, store) => {
	const inSavingState = computed(() => store.getters.inSavingState);
	const controller = new AbortController();

	if (config.method === 'post' || config.method === 'put') {
		if (inSavingState.value) {
			controller.abort();
		}
		store.commit('setSavingState', true);
	}
	config.timeout = TIMEOUT;
	config.url = `${SERVER_API_URL}${config.url}`;
	return {
		...config,
		signal: controller.signal,
	};
};

const setupAxiosInterceptors = (onUnauthenticated, onBadRequest, onServerError, store) => {
	const onResponseError = err => {
		store.commit('setSavingState', false);
		if (err.status || err.response) {
			const status = err.status || err.response.status;
			switch (status) {
				case 400:
					return onBadRequest(err);
				case 401:
				case 403:
					return onUnauthenticated(err);
				case status > 500:
					return onServerError(err);
				default:
					console.error(`unhandled error status code [${status}]:`, err);
					return Promise.reject(err);
			}
		}
	};

	const onResponseSuccess = res => {
		if (res.config.method === 'post' || res.config.method === 'put') {
			store.commit('setSavingState', false);
		}
		return res;
	};

	if (axios.interceptors) {
		store.commit('setSavingState', false);
		axios.interceptors.request.use(config => onRequestSuccess(config, store));
		axios.interceptors.response.use(onResponseSuccess, onResponseError);
	}
};

export { onRequestSuccess, setupAxiosInterceptors };
