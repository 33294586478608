import { BaseService } from '@/core/base-service';
import axios from 'axios';

export default class EmployeeService extends BaseService<any> {
	public constructor() {
		super('services/servicezef/api/employee');
	}

	public getCurrent(): Promise<any> {
		return axios.get(this.endpointName + `/current-employee`);
	}

	public setExitDate(exitDate, id): Promise<any> {
		return axios.post(this.endpointName + `/exit-date`, { exitDate: exitDate, id });
	}
}
