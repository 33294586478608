import axios from 'axios';
import { BaseService } from '@/core/base-service';

export default class BillingService extends BaseService<any> {
	public constructor() {
		super('services/servicezef/api/billing');
	}

	public createBilling(employeeIds: Array<string>, month: Date): Promise<any> {
		return axios.post(this.endpointName, { employeeIds: employeeIds, month: month });
	}

	public deleteLatest(employeeIds: Array<string>): Promise<any> {
		return axios.post(`${this.endpointName}/delete-latest`, { employeeIds: employeeIds });
	}

	public getBillings(employeeId: string): Promise<any> {
		return axios.get(`${this.endpointName}/employee?employeeId=${employeeId}`);
	}

	public downloadBilling(employeeIds: Array<string>, month: Date): Promise<any> {
		return axios.post(
			`${this.endpointName}-report/download?addWorktime=true`,
			{ employeeIds: employeeIds, month: month },
			{
				responseType: 'arraybuffer',
				headers: {
					Accept: 'application/pdf',
				},
			},
		);
	}

	public downloadCurrentJournal(employeeIds: Array<string>): Promise<any> {
		return axios.post(`${this.endpointName}-report/download-current-journal`, employeeIds, {
			responseType: 'arraybuffer',
			headers: {
				Accept: 'application/pdf',
			},
		});
	}
}
